<template>
  <div class="text-center" v-if="isLoading">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </div>
  <v-container class="fill-height" v-else-if="promotionFormVisible">
    <v-row justify="center">
      <v-col lg="8" md="10" sm="10" xs="12">
        <v-form @submit.stop.prevent="onSubmit" auto-complete="no">
          <v-card class="elevation-12">
            <v-toolbar color="info" dark flat>
              <v-toolbar-title>
                <span>{{ finalTitle }}</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on: on }">
                  <v-btn icon v-on="on" :to="{ name: 'ceurLanding' }">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>{{ $i18n.translate("Cancel") }}</span>
              </v-tooltip>
            </v-toolbar>

            <v-card-text>
              <ApiError :errors="errors"></ApiError>

              <v-stepper v-model="e1">
                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-form @submit.prevent="onSubmit" v-model="valid">
                      <v-container>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              name="firstName"
                              :label="this.$i18n.translate('First Name')"
                              id="firstName"
                              v-model="form.user.firstName"
                              :rules="rules.firstName"
                              :error-messages="$error.getValidationError(errors, 'firstName')"
                              @input="$error.clearValidationError(errors, 'firstName')"
                              autocomplete="no"
                              class="required"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              name="lastName"
                              :label="this.$i18n.translate('Last Name')"
                              id="lastName"
                              v-model="form.user.lastName"
                              :rules="rules.lastName"
                              :error-messages="$error.getValidationError(errors, 'lastName')"
                              @input="$error.clearValidationError(errors, 'lastName')"
                              autocomplete="no"
                              class="required"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12">
                            <EmailField
                              name="email"
                              :label="this.$i18n.translate('Email')"
                              id="email"
                              v-model="form.user.email"
                              :rules="rules.email"
                              :error-messages="$error.getValidationError(errors, 'email')"
                              @input="$error.clearValidationError(errors, 'email')"
                              autocomplete="no"
                              class="required"
                            ></EmailField>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <TermsAndConditionsView
                              :value="termsAndConditions"
                              @update:valid="validTermsAndConditions = $event"
                          /></v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <p>
                              By clicking 'Register' below, I agree to the Sony Electronics Inc.
                              <a href="https://electronics.sony.com/privacy-policy" target="_blank" rel="noreferrer"
                                >Privacy Policy
                              </a>
                              and certify that I am a U.S. resident.
                              <a
                                href="https://electronics.sony.com/privacy-policy#DataPractices"
                                target="_blank"
                                rel="noreferrer"
                              >
                                CA Privacy Notice
                              </a>
                            </p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-btn color="secondary" @click="backToLanding()">
                              Cancel
                            </v-btn>
                          </v-col>
                          <v-col class="text-right">
                            <v-btn
                              color="primary"
                              @click="onSubmit()"
                              :loading="isLoading"
                              :disabled="!valid || !validTermsAndConditions"
                            >
                              Register
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <p>
                              *To help ensure that you receive all email messages consistently in your inbox, please add
                              consumerrebateshq@rebatesupport.com to your address book or contact list.
                            </p>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-stepper-content>
                  <v-stepper-content step="2">
                    <p v-html="finalDescription"></p>
                    <v-row v-if="!isSuccess">
                      <v-col>
                        <v-btn color="primary" @click="onBack()">
                          Back
                        </v-btn>
                      </v-col>
                      <v-col class="text-right">
                        <v-btn color="secondary" @click="backToLanding()">
                          Cancel
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row v-else>
                      <v-col class="text-right">
                        <v-btn color="primary" @click="backToLanding()">
                          Finish
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <div class="white--text text-center">
      <p class="mb-5"></p>
      <h2>{{ noPromotionsMessage }}</h2>
      <p class="mb-5"></p>
    </div>
  </v-container>
</template>

<script>
import TermsAndConditionsView from "@/gapp-components/components/display/TermsAndConditionsView.vue";
import EmailField from "@/gapp-components/components/fields/EmailField.vue";
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import UtilService from "@/gapp-components/services/util.service.js";
import Vue from "vue";
import { mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  components: { ApiError, EmailField, TermsAndConditionsView },
  name: "CeurPromotionRegistration",
  metaInfo: {
    title: "Promotion Registration"
  },
  data() {
    return {
      isLoading: false,
      e1: 1,
      errors: {},
      promotionKey: "",
      isSuccess: false,
      valid: false,
      validTermsAndConditions: false,
      noPromotionsMessage: this.$i18n.translate("Check back with us soon for new rebates!"),
      rules: {
        firstName: [v => !!v || "First Name is required."],
        lastName: [v => !!v || "Last Name is required."],
        country: [v => (!!v && !!v.name) || "Country is required."],
        email: [v => !!v || "E-mail is required"],
        terms: [v => !!v || "Terms and Conditions are required"]
      },
      form: {
        programKey: process.env.VUE_APP_PROGRAM_CEUR_KEY,
        clientKey: process.env.VUE_APP_CLIENT_KEY,
        participantStatus: {},
        user: {
          firstName: "",
          lastName: "",
          email: "",
          username: ""
        },
        participant: {
          email1: "",
          phoneNumber1: "",
          address: {
            address1: "",
            address2: "",
            country: {},
            region: {},
            city: "",
            postalCode: ""
          },
          participantStatus: { name: "PRE" },
          termAndConditionAccepted: false
        },
        relationships: [],
        sendMail: true
      },
      program: null,
      finalDescription: "",
      termsAndConditions: {},
      finalTitle: "You must register here to receive the Rebate Offer via email",
      promotion: {},
      promotionDeadlines: []
    };
  },
  created() {
    this.$store.dispatch("logout");
    if (this.$gtag) {
      this.$gtag.event("access", {
        page: this.$route.name || "CeurPromotionRegistration"
      });
    }
    this.fetchData();
  },
  watch: {
    selectedCountry() {
      this.fetchData();
    }
  },
  mounted() {
    if (this.$gtag) {
      this.$gtag.pageview({
        page_path: this.$route.fullPath,
        page_title: "Registration",
        page_location: window.location.href
      });
    }
  },
  computed: {
    ...mapGetters(["selectedLocale", "selectedProgram", "selectedCountry"]),
    promotionFormVisible() {
      return this.promotion && this.promotion.id && this.promotionDeadlines && !this.isAfterAllSubmissionDeadlines;
    },
    isAfterAllSubmissionDeadlines() {
      if (!this.promotionDeadlines) return false;
      const now = moment();
      return this.promotionDeadlines.every(d => now.isAfter(d.submissionDeadlineDate));
    }
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      this.errors = {};
      Vue.set(this, "errors", []);
      this.$store.dispatch("logout");
      const postForm = {
        promotionKey: this.promotion.promotionKey,
        participant: {
          ...this.form.participant,
          participantType: this.selectedProgram.defaultOnEnrollParticipantType,
          user: this.form.user,
          email1: this.form.email,
          organization: this.form.organization,
          address: {
            ...this.form.participant.address,
            country: { name: this.selectedCountry?.name || "USA" }
          }
        }
      };
      this.$api
        .postWithCaptcha("/api/promotionInvitations/selfInvitation/public", postForm, null, this.promotion.promotionKey)
        .then(() => {
          this.e1 = 2;
          this.finalTitle = "Your Registration was successful.";
          this.finalDescription =
            "An email will be sent shortly to the email address you have provided on the form. <br><br> Thank you for choosing Sony! <br><br>";
          this.isSuccess = true;
          if (this.$gtag) {
            this.$gtag.event("submit", {
              event_category: "Registration",
              event_label: this.promotion.promotionKey
            });
          }
        })
        .catch(error => {
          let errorDescription = "";
          errorDescription = this.$api.getErrorsFromResponse(error).message;
          this.e1 = 2;
          this.finalTitle = "Registration error";
          this.finalDescription = errorDescription.startsWith("The user is already registered")
            ? "The email address you have provided has already been registered. Please call 1.888.793.1332 and request the email resent. <br><br> Thank you for choosing Sony!"
            : errorDescription;
          this.isSuccess = false;
          if (this.$gtag) {
            this.$gtag.event("error", {
              event_category: "Registration",
              event_label: errorDescription
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    fetchData() {
      this.isLoading = true;
      let filters = {
        country: {
          name: this.selectedLocale.countryType.name || "US"
        },
        promotionType: {
          promotionTypeKey: this.$route.meta.promotionTypeKey
        },
        isEffective: "Active"
      };
      UtilService.fetchPaginatedData("/api/promotions/search/eligible/public", filters)
        .then(promotions => {
          if (promotions.length > 0) {
            if (this.$route.meta.prefixPromotionKey) {
              const prefix = this.$route.meta.prefixPromotionKey;
              let filtered = promotions.filter(item => item.promotion.promotionKey.startsWith(prefix));
              if (filtered.length > 1) {
                console.error(
                  `Multiple promotions starting with prefix ${prefix} have been found. The first one will be used.`
                );
              }
              promotions = filtered.length > 0 ? [filtered[0]] : [];
            }
            if (promotions.length > 0) {
              let found = promotions[0];
              this.promotion = found.promotion;
              this.promotionDeadlines = found.promotionDeadlines;
              let selectedLocaleName = this.selectedLocale?.languageType?.name
                ? this.selectedLocale.languageType.name
                : "en";
              if (found.promotion?.promotionType?.termsAndConditions?.length > 0) {
                let tacs = found.promotion.promotionType.termsAndConditions.filter(
                  tac => tac.language.name == selectedLocaleName
                );
                if (tacs.length > 0) {
                  this.termsAndConditions = tacs[0];
                }
              }
            }
          }
        })
        .catch(error => {
          console.error("Error fetching promotions", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    backToLanding() {
      this.$router.push({ name: "ceurLanding" });
    },
    onBack() {
      this.finalTitle = "You must register here to receive the Rebate Offer via email";
      this.e1 -= 1;
    }
  }
};
</script>

<style>
.grecaptcha-badge {
  bottom: 40px !important;
}
</style>
